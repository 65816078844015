import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { general } from '../utils/Gifts/transforms.gifts.settings';
import transformGiftsData from '../utils/Gifts/transforms.gifts.data';
import { productsStorageSaveProducts } from '../store/Slices/gifts/productsStorageSlice';

// Load layout dynamically to avoid loading global styles for CNET app.
const GiftsLayout = dynamic(() => import('./gifts_layout'));

const GiftsApplication = (props) => {
  const {
    giftsSiteSettings = null,
    giftsData = null,
    isDataFromLocalStorage = true,
    entity = null,
    statusCode = 200,
    nonce = '',
  } = props;

  useEffect(() => {
    if (!isDataFromLocalStorage) {
      localStorage.setItem('giftsSiteSettings', JSON.stringify(giftsSiteSettings));
    }
  }, []);

  useEffect(() => {
    if (!isDataFromLocalStorage) {
      localStorage.setItem('giftsData', JSON.stringify(giftsData));
    }
  }, []);

  if (typeof window === 'undefined' && (!giftsSiteSettings || !giftsData)) {
    return <>Internal error</>;
  }

  return (
    <GiftsLayout
      {...props}
      nonce={nonce}
      entity={entity}
      statusCode={statusCode}
      siteContentSettings={giftsSiteSettings}
      giftsData={giftsData}
    />
  );
};

GiftsApplication.getInitialProps = async ({ store, ctx: { res, req, query, asPath } }) => {
  const initialProps = {
    query,
    originalUrl: req ? req.originalUrl : asPath,
    isDataFromLocalStorage: true,
  };

  if (res?.settings) {
    initialProps.giftsSiteSettings = await general(res.settings);

    if (
      initialProps.giftsSiteSettings.frontPage.homepageLink.url ===
      initialProps.originalUrl.split('?')[0]
    ) {
      res.redirect(
        301,
        `/${query.previewToken && query.previewMode ? `?previewMode=1&previewToken=${query.previewToken}` : ''}`,
      );
    }

    // Ignore redirects in Preview mode.
    if (initialProps.giftsSiteSettings.redirects.length && !query.previewToken) {
      // Handle redirects.
      const redirect = initialProps.giftsSiteSettings.redirects.find(
        (item) => item.fromUrl === initialProps.originalUrl.split('?')[0],
      );

      if (redirect) {
        res.redirect(+redirect.statusCode, redirect.toUrl);
      }
    }
  }

  if (res?.cwGiftsData) {
    const products = await transformGiftsData.products(
      res.cwGiftsData.products,
      initialProps.giftsSiteSettings,
    );
    initialProps.giftsData = {
      categories: await transformGiftsData.categories(
        res.cwGiftsData.categories,
        initialProps.giftsSiteSettings,
      ),
      pages: await transformGiftsData.pages(res.cwGiftsData.pages, initialProps.giftsSiteSettings),
    };

    initialProps.isDataFromLocalStorage = false;

    store.dispatch(productsStorageSaveProducts(products));
  }

  if (!res) {
    initialProps.giftsSiteSettings = JSON.parse(localStorage.getItem('giftsSiteSettings'));
    initialProps.giftsData = JSON.parse(localStorage.getItem('giftsData'));
  }

  // Determine the page entity for categories.
  if (initialProps.originalUrl.startsWith('/category/')) {
    const categories = initialProps.giftsData.categories;
    const results = Object.values(categories).filter(
      (item) => item.url.url === initialProps.originalUrl.split('?')[0],
    );

    if (!results.length) {
      initialProps.statusCode = 404;
    } else {
      initialProps.entity = results[0];
    }
  }
  // Determine the page entity for products.
  else if (initialProps.originalUrl.startsWith('/gifts/')) {
    const products = store.getState().productsStorage;
    const results = Object.values(products.gifts).filter(
      (item) => item.url.url === initialProps.originalUrl.split('?')[0],
    );

    if (!results.length) {
      initialProps.statusCode = 404;
    } else {
      initialProps.entity = results[0];
    }
  }
  // Determine the page entity for corporate products.
  else if (initialProps.originalUrl.startsWith('/corporate/')) {
    const products = store.getState().productsStorage;
    const results = Object.values(products.corporateGifts).filter(
      (item) => item.url.url === initialProps.originalUrl.split('?')[0],
    );

    if (!results.length) {
      initialProps.statusCode = 404;
    } else {
      initialProps.entity = results[0];
    }
  }
  // Determine the home page and gift cards.
  else if (
    initialProps.originalUrl === '/' ||
    initialProps.originalUrl.startsWith('/?') ||
    initialProps.originalUrl.startsWith('/gift-card/')
  ) {
    const pages = initialProps.giftsData.pages;

    const results = Object.values(pages).filter(
      (item) => item.url.url === initialProps.giftsSiteSettings.frontPage.homepageLink.url,
    );

    if (!results.length) {
      initialProps.statusCode = 404;
    } else {
      initialProps.entity = results[0];
    }
  }
  // Determine the page entity for other pages.
  else {
    const ignoredPaths = ['/basket', '/cards', '/checkout'];

    if (!ignoredPaths.some((path) => initialProps.originalUrl.startsWith(path))) {
      const pages = initialProps.giftsData.pages;

      const results = Object.values(pages).filter(
        (item) => item.url.url === initialProps.originalUrl.split('?')[0],
      );

      if (!results.length) {
        initialProps.statusCode = 404;
      } else {
        initialProps.entity = results[0];
      }
    }
  }

  // Set 404 status code.
  if (res && initialProps.statusCode === 404) {
    res.statusCode = 404;
  }

  return initialProps;
};

GiftsApplication.propTypes = {
  giftsSiteSettings: PropTypes.object,
  giftsData: PropTypes.object,
  isDataFromLocalStorage: PropTypes.bool,
  entity: PropTypes.object,
  statusCode: PropTypes.number,
  nonce: PropTypes.string,
};

export default GiftsApplication;
